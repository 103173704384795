






























































































import { Usuario } from '@/models'
import { FindUsuarioUseCase } from '@/usecases/usuario/FindUsuariosUseCase'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { nextTick } from '@/shareds/utils'
import SeletorDeUsuario from '@/components/usuario/SeletorDeUsuario.vue'
import { arrayObrigatorio } from '@/shareds/regras-de-form'

@Component({
	components: {
		SeletorDeUsuario, 
	},
})
export default class DialogoDeFiltroDeRelatorioDeVenda extends Vue {
	@Ref() form!: HTMLFormElement
	@Prop({type: Boolean, default: false}) carregando!: boolean
	@Prop({type: String, default: null}) lojaId!: string

	filtro = {
		vendedor: null as string | null,
		condicaoPagamento: ['À vista', 'Parcelado'],
		formaPagamento: ['Cartão', 'Dinheiro', 'Voucher', 'TEF Crédito', 'TEF Débito', 'Consignado', 'Demonstração', 'Link', 'Crediário', 'Brinde',  'Outro'],
		estadoDaVenda: 'Ambas',
	}

	findUsuarioUseCase = new FindUsuarioUseCase()
	vendedores: Usuario[] = []

	isReadonly = false

	mostra: boolean | null = null
	arrayObrigatorio = arrayObrigatorio

	mostrar(filtro: any) {
		this.filtro = filtro
		this.mostra = true
	}

	ocultar(){
		this.mostra = false
	}

	handleChange(){
		this.isReadonly = true;
	}

	get formaDePagamento() {
		return [
			{text:'Cartão', value:'Cartão'},
			{text:'Dinheiro', value:'Dinheiro'},
			{text:'Voucher', value:'Voucher'},
			{text:'TEF Crédito', value:'TEF Crédito'},
			{text:'TEF Débito', value:'TEF Débito'},
			{text:'Consignado', value:'Consignado'},
			{text:'Demonstração', value:'Demonstração'},
			{text:'Link', value:'Link'},
			{text:'Crediário', value:'Crediário'},
			{text:'Brinde', value:'Brinde'},
			{text:'Outros', value:'Outro'},
		]
	}

	get estadosDaVenda() {
		return [
			{text:'Somente Faturadas', value:'Somente Faturadas'},
			{text:'Somente não Faturadas', value:'Somente não Faturadas'},
			{text:'Ambas', value:'Ambas'},
		]
	}

	async aplicarFiltro() {
		if (!this.form.validate()) return
		await nextTick()
		this.$emit('buscar', this.filtro)
	}
}
