var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.aplicarFiltros.apply(null, arguments)}}},[_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":""}},[_c('SeletorDeLojasDoUsuario',{attrs:{"prepend-icon":"mdi-store","label":"Loja","clearable":"","idDaLojaSalva":_vm.filtro.lojaId},model:{value:(_vm.filtro.lojaId),callback:function ($$v) {_vm.$set(_vm.filtro, "lojaId", $$v)},expression:"filtro.lojaId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":""}},[_c('RangeDatePicker',{attrs:{"inicial":{
								label: 'Data Inicial',
								clearable: true,
							},"final":{
								label: 'Data Final',
								clearable: true,
							}},model:{value:(_vm.filtro.datas),callback:function ($$v) {_vm.$set(_vm.filtro, "datas", $$v)},expression:"filtro.datas"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('v-checkbox',{attrs:{"label":"Somente vendas fiscais"},model:{value:(_vm.filtro.somenteVendasFiscais),callback:function ($$v) {_vm.$set(_vm.filtro, "somenteVendasFiscais", $$v)},expression:"filtro.somenteVendasFiscais"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"filled":"","disabled":_vm.carregando},on:{"click":_vm.abrirFiltros}},[_c('v-icon',[_vm._v(" mdi-filter ")]),_vm._v(" Filtros ")],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.carregando,"disabled":_vm.carregando}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" Aplicar ")],1)],1)],1)],1)],1)],1),_c('v-card',[_c('DataTableDeCrudPaginado',{ref:"dataTablePaginado",attrs:{"headers":_vm.computedHeaders,"items":_vm.itensFormatados,"hideActions":"","hideCreate":"","loading":_vm.carregando,"onBuscarPagina":_vm.buscar},scopedSlots:_vm._u([{key:"item.dataHora",fn:function(ref){
							var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateTimeToPtBrFormat(item.dataHora))+" ")]}},{key:"item.displayStatusDaNota",fn:function(ref){
							var item = ref.item;
return [(item.notas.length)?_c('div',{staticClass:"v-text-truncate",staticStyle:{"white-space":"nowrap"}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('div',_vm._g({staticClass:"mensagem-do-status-nf"},on),[_vm._v(" "+_vm._s(item.notas[0].cstat)+" - "+_vm._s(_vm.mapErrosSefaz[item.notas[0].cstat])+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.notas[0].cstat)+" - "+_vm._s(_vm.mapErrosSefaz[item.notas[0].cstat]))])])],1):_vm._e(),_c('div',{staticClass:"d-flex flex-nowrap"},[(item.notas.length > 1)?_c('v-menu',{attrs:{"offset-x":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"}},on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c('v-card',_vm._l((item.notas.slice(1)),function(nota,indice){return _c('v-list-item',{key:indice},[_c('v-list-item-content',[(nota.cstat.length)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(nota.cstat)+" - "+_vm._s(_vm.mapErrosSefaz[nota.cstat])+" ")]):_vm._e()],1)],1)}),1)],1):_vm._e()],1)]}},{key:"body.append",fn:function(ref){
							var headers = ref.headers;
return [_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"d-flex justify-space-around"},[_c('strong',[_vm._v(" Valor total bruto: "+_vm._s(_vm.totalizadores && _vm.totalizadores.total ? _vm.totalizadores.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0..toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))+" ")]),_c('strong',[_vm._v(" Valor total liquido: "+_vm._s(_vm.totalizadores && _vm.totalizadores.totalLiquido ? _vm.totalizadores.totalLiquido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0..toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))+" ")]),_c('strong',[_vm._v(" Troco total: "+_vm._s(_vm.totalizadores && _vm.totalizadores.troco ? _vm.totalizadores.troco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0..toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))+" ")])])])])]}},{key:"item.displayVendedor",fn:function(ref){
							var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap"},[_c('div',{staticClass:"text-no-wrap pr-1"},[_vm._v(" "+_vm._s(item.displayVendedor.length > 0 ? item.displayVendedor[0].nome : '- -')+" ")]),(item.displayVendedor.length > 1)?_c('v-menu',{attrs:{"offset-x":"","open-on-hover":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"}},on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c('v-card',_vm._l((item.displayVendedor.slice(1)),function(vendedor,indice){return _c('v-list-item',{key:indice},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(vendedor.nome)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(vendedor.cpf)+" ")])],1)],1)}),1)],1):_vm._e()],1)]}}],null,true)})],1),_c('DialogoDeFiltroDeRelatorioDeVenda',{ref:"dialogoDeFiltros",attrs:{"carregando":_vm.carregando,"lojaId":_vm.filtro.lojaId},on:{"buscar":_vm.aplicarFiltros,"limpar":_vm.limparFiltros},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }