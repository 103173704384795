



















































































































































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue'
import { CancelToken} from 'axios'
import SeletorDeLojasDoUsuario from "@/components/loja/SeletorDeLojas.vue"
import { Desconto, Page, Pageable, ResumoDaVenda, TotalizadoresDeVenda } from '@/models'
import { FiltroDeRelatorioDeVendas } from '@/models/relatorios/RelatorioDeVendas'
import { FindRelatorioUseCase } from '@/usecases'
import { obterDisplayClienteDaVenda } from '@/shareds/venda-shareds'
import { dateTimeToPtBrFormat, formatDate } from '@/shareds/date/date-utils'
import moment from 'moment'
import mapErrosSefaz from '@/shareds/fiscal/tabelas/mapErrosSefaz'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import RangeDatePicker from '@/components/ui/RangeDatePicker.vue'
import { formatarMoeda } from '@/shareds/formatadores'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import DialogoDeFiltroDeRelatorioDeVenda from '@/components/relatorio/DialogoDeFiltroDeRelatorioDeVenda.vue'
import { nextTick } from '@/shareds/utils'

const FILTRO_DE_RELATORIO_VENDAS = 'FILTRO_DE_RELATORIO_VENDAS'

@Component({
	components: {
		DataTableDeCrudPaginado,
		SeletorDeLojasDoUsuario,
		RangeDatePicker,
		DialogoDeFiltroDeRelatorioDeVenda,
	},
})
export default class TelaDeRelatoriosDeVendas extends Vue {
	@Ref() dataTablePaginado!: DataTableDeCrudPaginado
	@Ref() dialogoDeFiltros!: DialogoDeFiltroDeRelatorioDeVenda
	vendas: ResumoDaVenda[] = []
	carregando = false
	findRelatorioUseCase = new FindRelatorioUseCase()

	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	filtro: FiltroDeRelatorioDeVendas = localStorage[FILTRO_DE_RELATORIO_VENDAS]
		? JSON.parse(localStorage[FILTRO_DE_RELATORIO_VENDAS])
		: {
			lojaId: null,
			datas: [],
			horas: [null, null],
			vendedor: null,
			condicaoPagamento: ['À vista', 'Parcelado'],
			formaPagamento: ['Cartão', 'Dinheiro', 'Voucher', 'TEF Crédito', 'TEF Débito', 'Consignado', 'Demonstração', 'Link', 'Crediário', 'Brinde', 'Outro'],
			estadoDaVenda: 'Ambas',
			somenteVendasFiscais: false,
		}

	headers = [
		{ text: 'Identificador', value: 'identificador' },
		{ text: 'Data e Hora', value: 'dataHora' },
		{ text: 'Loja', value: 'pontoDeVenda.loja.nomeFantasia', sortable: false },
		{ text: 'Último Status', value:'displayStatusDaNota', sortable: false },
		{ text: 'Vendedor',  value:'displayVendedor', sortable: false },
		{ text: 'Cliente',  value:'displayCliente', sortable: false },
		{ text: 'Total(R$)',  value:'displayTotalDaVenda', sortable: false },
		{ text: 'Valor Liquido',  value:'displaySubtotalDaVenda', sortable: false },
		{ text: 'Desconto',  value:'displayDesconto', sortable: false },
		{ text: 'Troco',  value:'displayTroco', sortable: false },
		{ text: 'Média de Itens',  value:'displayMediaItens', sortable: false },
		{ text: 'Condição de pagamento',  value:'displayCondicaoDePagamento', sortable: false },
		{ text: 'Forma de Pagamento',  value:'displayFormaDePagamento', sortable: false },
	]

	pagina: Page<ResumoDaVenda> | null = null
	paginaAtual = 1
	dataInvalida = false
	rangeData = 0

	totalizadores: TotalizadoresDeVenda | null = null

	limpeza = false

	mapErrosSefaz = mapErrosSefaz

	limparFiltros() {
		this.limpeza = true
		const filtroDefault: FiltroDeRelatorioDeVendas = {
			lojaId: null,
			datas: [],
			horas: [null, null],
			vendedor: null,
			condicaoPagamento: ['À vista', 'Parcelado'],
			formaPagamento: ['Cartão', 'Dinheiro', 'Voucher', 'TEF Crédito', 'TEF Débito', 'Consignado', 'Demonstração', 'Link', 'Crediário', 'Brinde', 'Outro'],
			estadoDaVenda: 'Ambas',
			somenteVendasFiscais: false,
		}
		
		this.carregando = true
		this.vendas = []
		this.totalizadores = null
		this.dialogoDeFiltros.filtro.vendedor = filtroDefault.vendedor
		this.dialogoDeFiltros.filtro.condicaoPagamento = filtroDefault.condicaoPagamento
		this.dialogoDeFiltros.filtro.formaPagamento = filtroDefault.formaPagamento
		this.dialogoDeFiltros.filtro.estadoDaVenda = filtroDefault.estadoDaVenda
		this.dialogoDeFiltros.ocultar()
		this.carregando = false
		setTimeout(() => this.limpeza = false, 1000)
	}

	calculaRangeData(){
		if(!this.filtro.datas[0] || !this.filtro.datas[1]) this.dataInvalida = true
		if(this.isReverseData()) {
			this.filtro.datas.reverse()
		}
		if(this.filtro.datas.length > 0) {
			const starts = moment(this.filtro.datas[0]+' 00:00:00','YYYY/MM/DD')
			let ends   = moment(this.filtro.datas[1]+' 00:00:00','YYYY/MM/DD')
			if(!this.filtro.datas[1]) {
				const now = new Date().toISOString().substring(0,10)
				ends = moment(formatDate(now) + ' 00:00:00','YYYY/MM/DD')
			}
			this.rangeData = Math.abs(starts.diff(ends,'days'))
			if(this.rangeData > 30) this.dataInvalida = true
		}
	}

	isReverseData() {
		if(!this.filtro.datas[0] || !this.filtro.datas[1]) return false
		if(this.filtro.datas[1] < this.filtro.datas[0]) return true
	}

	gerarDataHora(data?: string | null, hora?: string | null): string | undefined {
		return moment(`${data} ${hora}`,  'YYYY-MM-DD HH:mm').toISOString(true)
	}

	async buscar(paginavel: Pageable, cancelToken: CancelToken) {
		await nextTick()
		try {
			this.carregando = true
			if (!this.filtro.lojaId) return []
			const pagina = await this.findRelatorioUseCase.listVendas({
				...this.filtrosFormatados,
				...paginavel,
			}, {cancelToken})
			this.vendas = pagina.content
			this.totalizadores = await this.findRelatorioUseCase.getTotalDeVendas({
				...this.filtrosFormatados,
			}, {cancelToken});
			this.dialogoDeFiltros.ocultar()
			
			return pagina
		} catch (e) {
			AlertModule.setError(e)
		} finally {
			this.carregando = false
		}
	}

	get itensFormatados() {
		return this.vendas.map(venda => ({
			...venda,
			displayVendedor: !venda.vendedor ? [] : venda.vendedor,
			displayCliente: !venda.cliente ? obterDisplayClienteDaVenda(venda) : venda.cliente.razaoSocialOuNome,
			displayTotalDaVenda: this.displayTotalDaVenda(venda),
			displaySubtotalDaVenda: this.displaySubtotalDaVenda(venda, venda.pagamentos),
			displayDesconto: !venda.desconto ? '-' : this.displayDesconto(venda.desconto),
			displayTroco: !venda.troco ? '0,00' : formatarMoeda(venda.troco),
			displayFormaDePagamento: this.displayFormaDePagamento(venda.pagamentos),
			displayCondicaoDePagamento: this.displayCondicaoDePagamento(venda.pagamentos),
			displayStatusDaNota: this.displayStatusDaNota(venda),
			displayMediaItens: !venda.quantidade ? formatarMoeda(0) :this.displayMediaItens(venda),
		}))
	}

	get getSubTotal() {
		const subtotaisDasVendas = this.itensFormatados ? this.itensFormatados.map(item => item.displaySubtotalDaVenda ? Number(item.displaySubtotalDaVenda.replace('.','').replace(/,/g, '.')) : 0) : []

		return subtotaisDasVendas.length > 0 ? subtotaisDasVendas.reduce((total, valor) => total + valor).toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		}) : '0'
	}

	get getMediaItens() {
		const total = this.getSubTotal ? Number(this.getSubTotal.replace(/[^\d.,-]/g, '').replace('.','').replace(/,/g, '.')) : 0
		const quantidade = this.totalizadores && this.totalizadores.quantidade ? this.totalizadores.quantidade : 0
		return (total / quantidade).toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		})
	}

	displayTotalDaVenda(venda: ResumoDaVenda) {
		if (!venda.total) return 0

		return formatarMoeda(venda.total)
	}
	get computedHeaders() {
		return this.headers.filter(
			({ value }) => value !== 'displayTotalDaVenda' || !this.ocultarColunaDeTotalDaVenda,
		)
	}
	get ocultarColunaDeTotalDaVenda() {
		return UserLoginStore.permiteRegraDeNegocio('ocultar-valor-das-vendas')
	}

	get filtrosFormatados() {
		const dataHoraInicial = this.gerarDataHora(this.filtro.datas[0], this.filtro.horas[0] || '00:00')
		const dataHoraFinal = this.gerarDataHora(this.filtro.datas[1], this.filtro.horas[1] || '23:59')
		const novoFiltro = {
			lojaId: this.filtro.lojaId,
			dataHoraInicial,
			dataHoraFinal,
			vendedor: this.filtro.vendedor,
			condicaoPagamento: Array.isArray(this.filtro.condicaoPagamento) ? this.filtro.condicaoPagamento.join(',') : this.filtro.condicaoPagamento || undefined,
			formaPagamento: Array.isArray(this.filtro.formaPagamento) ? this.filtro.formaPagamento.join(',') : this.filtro.formaPagamento,
			estadoDaVenda: this.filtro.estadoDaVenda,
			somenteVendasFiscais: this.filtro.somenteVendasFiscais,
		}
		return novoFiltro
	}

	displayStatusDaNota(venda: ResumoDaVenda) {
		if(!venda.notas){
			return
		}
		const nota = venda.notas[0]
		return nota && mapErrosSefaz[nota.cstat]
			? nota.cstat + ' - ' + mapErrosSefaz[nota.cstat]
			: nota?.cstat || '-'
	}

	displayDesconto(desconto: Desconto) {
		if(!desconto.isPercentual)
			return 'R$ '+ formatarMoeda(desconto.valor)
		return desconto.valor + '%'
	}

	displaySubtotalDaVenda(venda: ResumoDaVenda, pagamentos: any[]) {
		if (!venda.total) return '-'
		const pagamentosFiltrado = pagamentos.filter(pagamento => {
			return this.filtro.condicaoPagamento.includes(pagamento.avista ? "À vista" : "Parcelado") && this.filtro.formaPagamento.includes(pagamento.tipoDePagamento.formaDePagamento)
		})

		const valorDosPagamentos = pagamentosFiltrado ? pagamentos.map(pagamento => pagamento.valor - venda.troco) : []
		const totalPagamentosFiltrados = valorDosPagamentos.length > 0 ? valorDosPagamentos.reduce((total, valor) => total + valor) : 0

		return formatarMoeda(totalPagamentosFiltrados)
	}

	displayMediaItens(venda: ResumoDaVenda) {
		if(!venda.total || !venda.quantidade) return 0
		return formatarMoeda(venda.total / venda.quantidade)
	}

	displayCondicaoDePagamento(pagamentos: any[]) {
		const condicoes = pagamentos.map(pagamento => {
			if (pagamento.qtdeDeParcelas > 1)
				return ' '+ pagamento.qtdeDeParcelas + 'x de ' + formatarMoeda(pagamento.valorDaParcela)
			return ' À vista'
		})
		return condicoes
	}

	displayFormaDePagamento(pagamentos: any[]) {
		const tipos = pagamentos.map(pagamento => ' ' + pagamento.tipoDePagamento.nome)
		return tipos
	}

	async aplicarFiltros(filtro: any) {
		this.calculaRangeData()
		
		if(this.limpeza == false) {
			if (!this.filtro.lojaId) AlertModule.setError('Selecione uma loja para prosseguir')
			if (!this.filtro.datas[0]) AlertModule.setError('Selecione um período')
			if (this.rangeData > 31) AlertModule.setError('Seleção inválida! Selecione um período de até 31 dias.')
		}

		if(!this.filtro.lojaId || !this.filtro.datas[0] || this.rangeData > 31) {
			this.filtro.lojaId = null
			this.filtro.datas = [null, null]
			this.dataTablePaginado.reiniciar()
		} 
		else {
			this.dataTablePaginado.reiniciar()
		}

		if(filtro) {
			this.filtro = {
				...this.filtro,
				...filtro,
			}
		}
		if(!this.dataInvalida)
			this.dataTablePaginado.reiniciar(this.filtrosFormatados)
	}

	abrirFiltros() {
		this.dialogoDeFiltros.mostrar(this.filtro)
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.buscar

	@Watch('filtro', { deep: true })
	onChangeFiltro(newFiltro) {
		const stringified = JSON.stringify(newFiltro);
		localStorage.setItem(FILTRO_DE_RELATORIO_VENDAS, stringified)
	}

}

